import React from 'react'
import { graphql } from 'gatsby'
import SEO from '~/components/seo'
import styled from '@emotion/styled'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {
  Grid, Product, Title, PriceTag, Cell, GridContainer
} from './styles'
import { Img, SectionContainer } from '~/utils/styles'


const Wrapper = styled.div`
  margin-top: 40px;
  min-height: 320px;
`

const CollectionPage = ({data}) => {
  const stuffer = data.stuff.edges[0].node.products

  return (
    <>     
	  <SEO title={`Category - ${data.collection.title}`}/>
		<Wrapper>
          <SectionContainer>
            <GridContainer>
              <Grid>
                {stuffer
                ? stuffer.map(({title, handle, variants, images},i) => (
                  <Cell key={i}>
                  <AniLink paintDrip
                    to={`/product/${handle}/`}
                    color="pink">
                    <Product>
                      <Img 
                        fixed={images[0].localFile.childImageSharp.fixed}
                        alt={title}
                      />
                    <Title>{title}</Title>
                    <PriceTag>${variants[0].price}</PriceTag>
                    </Product>
                  </AniLink>
                  </Cell>
                ))
                : <p>No Products found!</p>}
              </Grid>
            </GridContainer>
          </SectionContainer>    
		</Wrapper>
    </>
  )
}
export const query = graphql`
  query ($handle: String!){
    stuff: allShopifyCollection(filter: {handle: {eq: $handle}}) {
      edges {
        node {
          id
          products {
            images {
              id
              originalSrc
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                  fixed(cropFocus: CENTER, width: 175, height: 175) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
            title
			      handle
            variants {
              price
            }
            description
          }
        }
      }
    }
    collection: shopifyCollection(handle: {eq: $handle}) {
      id
      handle
      products {
        images {
          id
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 910) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
              fixed(cropFocus: CENTER, width: 175, height: 175) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        title
        priceRange {
          maxVariantPrice {
            amount
          }
        }
        description
      }
      title
    }
  } 
`

export default CollectionPage

